import React from 'react';
import { Responsive, Segment } from 'semantic-ui-react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Products from '../components/Products';

const IndexPage = ({ location }) => (
  <Layout slimHeader={false} location={location}>
    <SEO title="Home" keywords={['duck', 'hunt', 'Georgia', 'Hartwell']} />
    <Responsive>
      <Segment style={{ padding: '4em 0em' }} vertical>
        <Products />
      </Segment>
    </Responsive>
  </Layout>
);

export default IndexPage;
