import React, { useContext } from "react";
import { ProductsContext } from "./ProductsProvider";
import ProductThumbnail from "./ProductThumbnail";

import { Grid } from "semantic-ui-react";

const Products = () => {
  const { listProducts } = useContext(ProductsContext);
  const products = listProducts();

  return (
    <Grid container stackable verticalAlign="middle">
      <Grid.Row columns={3}>
        {products.map(product => (
          <Grid.Column key={product.id}>
            <ProductThumbnail product={product} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default Products;
