import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { Card, Button } from "semantic-ui-react";

const ProductThumbnail = ({
  product: { id, name, localFiles, skus, slug }
}) => (
  <Card key={id}>
    <Img fluid={localFiles[0].childImageSharp.fluid} alt={name} />
    <Card.Content>
      <Card.Header>{name}</Card.Header>
      <Card.Meta>
        <span className="date">Price</span>
      </Card.Meta>
      <Card.Description>${skus[0].price / 100}</Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Button primary as={Link} to={`/buy/${slug}`}>
        Buy
      </Button>
    </Card.Content>
  </Card>
);

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductThumbnail;
